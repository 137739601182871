import React, { useEffect, useState } from 'react';
import New from './New';
import Preheader from './Preheader';
import Parters from './Parters';
import Footer from './Footer';
import { Helmet } from 'react-helmet';
export default function Auction() {

    const [standings, setStandings] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchStandings = async () => {
        try {
          const response = await fetch(
            'https://dev-upkl.upkl.in/api/resource/Brij%20Auction?fields=["*"]'
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
  
          const sortedData = data.data.sort((a, b) => a.position - b.position);
  
          setStandings(sortedData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
  
      fetchStandings();
    }, []); 
  const [imageSrc, setImageSrc] = useState('');

 

  return (
    <>

<Helmet>
        <title>
 
        Brij Stars Mega Auction - Players Bidding and Highlights

        </title>
        <meta
          name="title"
          content="Brij Stars Mega Auction - Players Bidding and Highlights"
        />
        <meta
          name="keywords"
          content=" 
 	
Brijstars Mega Auction
Players bidding
"
        />

        <meta
          name="description"
          content="Explore the Brij Stars Mega Auction and witness the exciting players' bidding process. Stay updated on the latest bids, player selections, and highlights from the auction. Discover the new talents joining Brij Stars and their impact on the team."
        />

        <link rel="canonical" href="https://brijstars./auction" />
      </Helmet>


      <Preheader />
      <div className="container-fluid p-0">
        <div className="breadcrumb-bg">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <div className="newheading1">
  <h5>Auction</h5>
  <div className="line"></div>
</div>            </ol>
          </nav>
        </div>
      </div>
    <section className='auction'>
    <div className="container-fluid">
        <div className="row">
        {standings.length > 0 ? (
                    standings.map((team, index) => (


          <div className="col-md-4 col-12">


<div className="media-card">
     <div class="card">
        <img src={`https://dev-upkl.upkl.in/${team.auction_image}`} alt="Card Image"/>
        
    </div>
      </div>
          </div>

))
) : (
    <>
    </>
)}
        </div>
      </div>
    </section>
      <Parters />
      <Footer />
    </>
  );
}
