// import React from 'react';



import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./image/logo.svg";
import fb from "./image/L1 (1).svg";
import inst from "./image/L1 (2).svg";
import Ln from "./image/L1 (3).svg";
import banner  from './image/bannerimage.svg'
import sony from './image/sony.svg'
import news from './image/news.svg'
import logovs from './image/logovs.svg'
import New from "./New";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import Preheader from "./Preheader";
import Table from "./Table";
import Parters from "./Parters";
import Footer from "./Footer";
import Fixture from "./Fixture";
import teamplayer from './image/teamplayer.png'
import { Helmet } from "react-helmet";
import player1 from './playerimg/12 vikrant choudhary.jpg'
import player2 from './playerimg/14 karan yadav (1).jpg'
import player3 from './playerimg/14 karan yadav.jpg'
import player4 from './playerimg/02 robin chaudhary.jpg'
import player5 from './playerimg/15 pankaj.jpg'
import player6 from './playerimg/03 harsh dhaka.jpg'
import player7 from './playerimg/07 vishal patil.jpg'
import player8 from './playerimg/08 shakti chaudhary.jpg'
import player9 from './playerimg/11 lakshya tomer.jpg'
import player10 from './playerimg/10 lucky kumar.jpg'
import player11 from './playerimg/04 arjun singh.jpg'
import player12 from './playerimg/05 abhishek dangi.jpg'
import bannernew from './image/brijbanner.jpg'
// import partner from './image/sponsor.png's

function Home() {

  const [standings, setStandings] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchStandings = async () => {
        try {
          const response = await fetch(
            'https://dev-upkl.upkl.in/api/resource/Standings?fields=["*"]'
          );
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          const data = await response.json();
  
          const sortedData = data.data.sort((a, b) => a.position - b.position);
  
          setStandings(sortedData);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(false);
        }
      };
  
      fetchStandings();
    }, []); 



    const options5 = {
        loop: true,
        margin: 10,
        center: true,
        items: 3,
        nav: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 3
            }
        }
    };
  
  const options = {
    loop: true,
    margin:25,
    nav: false,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    dots:false,
    center: true,
    responsive: {
      0: {
        items: 1.6
      },
      600: {
        items: 3
      },
      1000: {
        items: 4.2
      }
    }
  };


  return (
    <>


<Helmet>
        <title>
 
        Brij Stars Kabaddi Team 2024 | Watch Live Match on Sony Sports and FanCode | Official Brij Stars Website

        </title>
        <meta
          name="title"
          content="Brij stars
sony sports
Brij stars website
Kabaddi 2024
Brij stars kabaddi team
"
        />
        <meta
          name="keywords"
          content="Brij Stars Kabaddi Team 2024 | Watch Live Match on Sony Sports and FanCode | Official Brij Stars Website"
        />

        <meta
          name="description"
          content="Explore the official Brij Stars website for the latest updates on the Brij stars Kabaddi team in 2024. Stay tuned for exclusive Sony Sports and FanCode coverage and all the action from the Kabaddi 2024 season
"
        />

        <link rel="canonical" href="https://brijstars.com" />
      </Helmet>



    <Preheader/>


      <div className="bannerhome">
        <img src={bannernew} className="img-fluid" />
      </div>


  <Fixture/>

  <section className='standingtable'>
  <div class="overlay2"></div>

    <div class="container-fluid">
    <div className="newheading1">
  <h5>Standings</h5>
  <div className="line"></div>
</div>
      <div class="table-responsive">
      <table className="table table-bordered table-hover">
  <thead className="table-dark">
    <tr>
      <th className="text-center">SI NO.</th>
      <th></th>
      <th>TEAMS NAME</th>
      <th>P</th>
      <th>W</th>
      <th>L</th>
      <th className="mobilenone">T</th>
      <th className="mobilenone">+/-</th>
      <th className="mobilenone">PTS</th>
      <th className="text-center mobilenone">FORM</th>
    </tr>
  </thead>
  <tbody>
                  {standings.length > 0 ? (
                    standings.map((team, index) => (
                      <tr  key={index}
                      className={team.team === "BRIJ STARS" ? "highlight" : ""}>
                        <td className="text-center">{index + 1}</td>
                        <td>
                          <img
                            src={`https://dev-upkl.upkl.in/${team.image}`}
                            alt={team.team}
                          />
                        </td>{" "}
                        <td>{team.team}</td>
                        <td>{team.matches}</td>
                        <td>
                        {team.won ? ` ${team.won} ` : '-'}
                        </td>
                        <td>
                        {team.lost ? ` ${team.lost} ` : '-'}
                        </td>
                        <td className="mobilenone">
                        {team.tied ? ` ${team.tied} ` : '-'}
                         </td>
                        <td className="mobilenone">  {team.score_diff ? ` ${team.score_diff} ` : '-'}</td>
                        <td className="mobilenone">   {team.points ? ` ${team.points} ` : '-'}</td>
                        <td className="mobilenone">
                        <ul>
                            <li className={team.f1 === "W" ? "win" : team.f1 === "L" ? "loss" : team.f1 === "tie-not" || !team.f1 ? "T" : "Tie"}>{team.f1}</li>
                            <li className={team.f2 === "W" ? "win" : team.f2 === "L" ? "loss" : team.f2 === "tie-not" || !team.f2 ? "T" : "Tie"}>{team.f2}</li>
                            <li className={team.f3 === "W" ? "win" : team.f3 === "L" ? "loss" : team.f3 === "tie-not" || !team.f3 ? "T" : "Tie"}>{team.f3}</li>
                            <li className={team.f4 === "W" ? "win" : team.f4 === "L" ? "loss" : team.f4 === "tie-not" || !team.f4 ? "T" : "Tie"}>{team.f4}</li>
                            <li className={team.f5 === "W" ? "win" : team.f5 === "L" ? "loss" : team.f5 === "tie-not" || !team.f5 ? "T" : "Tie"}>{team.f5}</li>
                          </ul>
                        </td>
                        {/* Render other data accordingly */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">Loading...</td>
                      <td colSpan="3"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>
<td className="mobilenone"></td>


                    
                      
                    </tr>
                  )}
                </tbody>
</table>

      </div>
  </div>


    </section>

      {/* <section className="socialplatform"> 
  <div  className="socialmediamargin">

 <iframe src="https://widget.taggbox.com/157253"         style={{ width: '100%', height: '580px', border: 'none' }} 
     ></iframe>
    
      
      </div>   
      
        </section> */}

     
        <section className="playersider">

<div className="newheading1">
<h5>Squad</h5>
<div className="line"></div>
</div>


<OwlCarousel className="owl-theme" {...options}>
<div className='items'>
          <img src={player1} className='img-fluid'/>
      </div>

      <div className='items'>
          <img src={player2} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player3} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player4} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player5} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player6} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player7} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player8} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player9} className='img-fluid'/>
      </div>
      <div className='items'>
          <img src={player10} className='img-fluid'/>
      </div>

      <div className='items'>
          <img src={player11} className='img-fluid'/>
      </div>


      <div className='items'>
          <img src={player12} className='img-fluid'/>
      </div>

</OwlCarousel>
</section>

<New/>
     
<Parters/>
  <Footer/>




    </>
  );
}

export default Home;