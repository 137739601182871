import React, { useState } from 'react';
import logo from "./logobrij.png";
import fb from "./image/L1 (1).svg";
import inst from "./image/L1 (2).svg";
import Ln from "./image/L1 (3).svg";
import {Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';


import Swal from 'sweetalert2';

export default function Preheader() {

    const [formData, setFormData] = useState({
        name1: '',
        email: '',
        number: '',
        message: ''
      });
    
      const [showSuccessModal, setShowSuccessModal] = useState(false);
    
      const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [id]: value
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch('https://dev-upkl.upkl.in/api/resource/Brij%20Contact?fields=[%22*%22]', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Your message has been sent successfully!'
              });


          } else {
            console.error('Failed to submit form', response.statusText);
          }
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };

      
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-11">
           <Link to="/"> <img src={logo} className="logo" /></Link>
          </div>
          <div className="col-111">
            <div className="preheader d-flex mnone">
              {/* <div class="marquee"> */}
              
                <p className='text-center'>Catch us playing live on 11th July - 25th July on Sony Sports and Fancode. &nbsp; &nbsp; &nbsp;| &nbsp; &nbsp; &nbsp; Stay tuned on instagram handle @brij.stars for latest updates!</p>
              {/* </div> */}
             
            </div>
            <nav className="navbar navbar-expand-lg navbar-dark bg-coko">
            <Link to="/">  <img src={logo} className="logo1 d-none" /></Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mr-auto">
      <li className="nav-item">
        <NavLink exact className="nav-link" activeClassName="active" to="/">
          Home
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" activeClassName="active" to="/about-us">
          About Us
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" activeClassName="active" to="/squad">
          Squad
        </NavLink>
      </li>
      {/* <li className="nav-item">
        <a className="nav-link" href="#matches">
          Matches
        </a>
      </li> */}
      <li className="nav-item">
        <NavLink className="nav-link" activeClassName="active" to="/standing">
          Standings
        </NavLink>
      </li>
      {/* <li className="nav-item">
        <NavLink className="nav-link" activeClassName="active" to="/news">
          News
        </NavLink>
      </li> */}
      <li className="nav-item">
        <NavLink className="nav-link" activeClassName="active" to="/auction">
          Auction
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink className="nav-link" activeClassName="active" to="/video">
          Videos
        </NavLink>
      </li>
      <li className="nav-item">
        <button  data-toggle="modal" data-target="#exampleModalCenter" class="button-33 sponsorbtn" role="button">Sponsorship </button>

      </li>
    </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>



      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Apply for Sponsorship</h5>
        {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close"> */}
          {/* <span aria-hidden="true">&times;</span>
        </button> */}
      </div>
      <div class="modal-body">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            id="name1"
            value={formData.name1}
            onChange={handleChange}
            placeholder="Full Name"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            id="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email Address"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            maxLength="10"
            className="form-control"
            id="number"
            value={formData.number}
            onChange={handleChange}
            placeholder="Mobile Number"
            required
          />
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            id="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            rows="3"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>

      </div>
    
    </div>
  </div>
</div>
</>
  )
}
