import React from 'react'
import logo from "./Brij White Logo.png";
import { Link } from 'react-router-dom';
import sponsor from './flexveda-x-ss-groups-03.png'
export default function Footer() {
  return (
  <>

  <section className='sponsor1'>
  <div className="newheading">
                    <h5>Our Sponsor</h5>
                    <div className='line'></div>
                </div>
    <div className='logosponsor'>
      <div className=''></div>
      <img src={sponsor} className='img-fluid'/>
    </div>
  </section>
  
      <section className="footer mnone">
        <div className='logofoter'>
          <img src={logo} className='img-fluid footerlogo'/>
        </div>
<div className="footermenu">
 <ul className="d-flex">
  <li>About Us


</li>

  <li>Standings </li>
  <li>Squad</li>
  <li>News</li>
  <li>Auction</li>


 </ul>
</div>

      </section>
      
<div className="footercpoy">
  <p>&copy; 2024 Brij Stars. All rights reserved.</p>
</div>



<div class="s-soft">
<Link to="https://www.facebook.com/profile.php?id=61560819575510" target='_blank' class="s-item facebook">
<span class="fa fa-facebook"></span>
</Link>

<a href="https://www.instagram.com/brij.stars/" target='_blank' class="s-item pinterest">
<span  class="fa fa-instagram"></span>
</a>

<a href="https://www.youtube.com/@BrijStars" target='_blank' class="s-item pinterest">
<span  class="fa fa-youtube-play"></span>
</a>


</div></>


  )
}
