import React from 'react'
import Preheader from './Preheader'
import Parters from './Parters'
import Footer from './Footer'
import founder from './image/founderbrij.jpg'
import team1 from './image/t1.jpg'
import team2 from './image/t2.jpg'
import team3 from './image/t31.png'
import team4 from './image/t41.png'
import aboutus from './image/about-us.jpg'
import { Helmet } from 'react-helmet'
export default function About() {
  return (
   <>

<Helmet>
        <title>
        About Brij Stars - Founded by Mr. Netrapal Singh Solanki, A Legacy of Kabaddi in Mathura.


        </title>
        <meta
          name="title"
          content="About Brij Stars - Founded by Mr. Netrapal Singh Solanki, A Legacy of Kabaddi in Mathura.

"
        />
        <meta
          name="keywords"
          content=" 
Brij stars
Founder of Brij stars
Uttar Pradesh Kabaddi League
kabaddi team 2024
kabaddi stars
Kabaddi team of Mathura
"
        />

        <meta
          name="description"
          content="Brij Stars, the prominent kabaddi team from Mathura, founded by passionate kabaddi stars. Competing in the Uttar Pradesh Kabaddi League 2024, BrijStars showcases exceptional talent and dedication. Learn more about our journey and achievements in the world of kabaddi.

"
        />

        <link rel="canonical" href="https://brijstars./about" />
      </Helmet>


     <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>About Us</h5>
  <div className="line"></div>
</div>                </ol>
            </nav>
        </div>
        </div>

        <section className='about--us'>
        <div className='container-fluid'>
            <div className='abou-us'>
              <div className='row'>
               

              <div className='col-md-5 col-12'>
                    <div className='leftimage'>
                        <img src={aboutus} className='img-fluid'/>
                    </div>
                </div>

                <div className='col-md-7 col-12 align-self-center'>
                    <div className='rigthabout'>
                        <h5>Brij Stars</h5>
                       <p>Welcome to Brij Stars, a proud contender in the vibrant arena of the Uttar Pradesh Kabaddi League. Founded by the visionary Netrapal Solanki, our team embodies the spirit of courage, strategy, and relentless determination.
</p><p>
At Brij Stars, we believe in the power of unity and the thrill of competition. With each match, we strive to showcase the rich heritage of Kabaddi, deeply rooted in the heart of Uttar Pradesh. Our players, handpicked for their agility and skill, form a formidable force ready to take on any challenge.
</p>
                    </div>
                </div>
              </div>
            </div>
        </div>
        </section>

        <section className='kashifounder'>
        <div className="newheading">
                    <h5>Founder Message</h5>
                    <div className='line'></div>
                </div>
       <div className='container-fluid'>
        
    
       <div className='founder'>
            <div className='row'>
                <div className='col-md-4 col-12 align-self-center'>
                    <img src={founder} className='img-fluid'/>
                </div>
                <div className='col-md-8 col-12 align-self-center'>
                    <div className='founderpara'>
                        <p>Brij Stars is amongst one of the proud participants in the Uttar Pradesh Kabaddi League. As the founder, I, Netrapal Solanki, am thrilled to introduce you to our team and share our vision with you.</p>
                        <p>Kabaddi, a sport deeply rooted in our culture, is not just a game for us; it is a way of life. The Brij Stars team embodies the spirit of determination, courage, and unity that defines kabaddi. Our journey began with a simple yet powerful goal: to promote and elevate the sport of kabaddi in Uttar Pradesh and beyond.</p>
                      
                      <p>At Brij Stars, we are committed to nurturing local talent and providing them with the best training and facilities to excel. Our players are a testament to hard work, perseverance, and passion. Each member of our team brings unique strengths and skills, contributing to our dynamic and formidable squad.
                      We believe in the power of sports to bring communities together, inspire the youth, and instill values of teamwork and resilience. Through our participation in the Uttar Pradesh Kabaddi League, we aim to set new standards of excellence and sportsmanship.</p>
                       <br></br><div className='postion'>
                            <h6>MR. NETRAPAL SOLANKI</h6>
                            <p>Founder | BRIJ STARS</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </div>
        </section>

        <section className='misionbox'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='cardmission'>
                            <h5>Mission</h5>
<p>To bring young athletes from small towns and give them a chance to play and make their big move. 

In this league, specifically the mission is to nurture fresh players to the level of international players so that they can participate in international leagues of Kabaddi</p>
                        </div>
                    </div>


                    <div className='col-12 col-md-6'>
                        <div className='cardmission'>
                            <h5>Vision</h5>
<p>To empower Kabaddi Players in UP , to generate opportunities and a strong career choice for people, To keep the game of ancient India- Kabaddi alive in the upcoming generation.</p>
<p></p>
<br></br>
                        </div>
                    </div>
                </div>
            </div>
        </section>

     
        <Parters/>
        <Footer/>
   
   </>
  )
}
