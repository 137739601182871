import React from 'react'
import Preheader from './Preheader'
import Table from './Table'
import Parters from './Parters'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
export default function Standing() {
  return (
  <>


<Helmet>
        <title>
 
        Brij Stars Kabaddi League Results & Scores | Brij Stars Match Outcomes & Points Table

        </title>
        <meta
          name="title"
          content="Brij Stars Kabaddi League Results & Scores | Brij Stars Match Outcomes & Points Table
"
        />
        <meta
          name="keywords"
          content=" 
 Brij stars players
Brij stars players name
players of Brij stars
Brij stars Allrounders
Brij stars raiders
Brij stars defenders
"
        />

        <meta
          name="description"
          content="Brij Stars in the UP Kabaddi League (UPKL) and stay updated on kabaddi tournaments in UP. Check the latest points table and see how Brij Stars ranks against the competition. Discover match schedules, results, and league standings.
"
        />

        <link rel="canonical" href="https://brijstars./standings" />
      </Helmet>


  <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>Standings</h5>
  <div className="line"></div>
</div>          
                </ol>
            </nav>
        </div>
        </div>

        <Table/>
        <Parters/>
        <Footer/>
  </>
  )
}
