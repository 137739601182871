import React, { useEffect } from 'react';
import news from './image/news.svg'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import $ from 'jquery';
import news1 from './image/news4.jpg'
import news2 from './image/news2.jpg'
import news3 from './image/news1.jpg'
import { Link } from 'react-router-dom';


export default function New() {

    // useEffect(() => {
    //     const owl = $('.owl-carousel');
    
    //     owl.on('changed.owl.carousel', function(event) {
    //       const items = $('.owl-item', owl);
    //       items.removeClass('center non-center');
    //       items.each(function() {
    //         if ($(this).hasClass('active')) {
    //           $(this).addClass('center');
    //         } else {
    //           $(this).addClass('non-center');
    //         }
    //       });
    //     });
    //   }, []);

      
    

    const options = {
        items: 5,
        loop: true,
        margin: 20,
        nav: false,
        dots:false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        responsive: {
          0: {
            items: 1.2
          },
          600: {
            items: 3
          },
          1000: {
            items: 3.6
          }

        }
      };

      const options3 = {
        items: 3,
        loop: true,
        margin: 20,
        nav: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        center: true,
        responsive: {
          0: {
            items:1.3
          },
          600: {
            items: 3
          },
          1000: {
            items: 3
          }
        }
      };
  return (


 <>
 <section className="newmedia">
  <div className="newheadingmedia">
    <h5>News & Media</h5>
    <div className='line2'></div>
    </div>

    <OwlCarousel className="owl-theme" {...options}>
    <div className="media-card">
     <div class="card">
        <img src="https://dev-upkl.upkl.in//files/the%20daily%20guardian%20(1).jpg" alt="Card Image"/>
        <div class="overlay">
        <Link to="https://tricitytoday.com/noida/up-kabaddi-league-will-be-held-from-11th-july-to-25th-july-in-noida-indoor-stadium-55464.html" target="_blank">

            <h2 class="title">Uttar Pradesh Kabaddi League's inaugural edition</h2>
            <p class="date">June 12, 2024</p>
            </Link>
        </div>
    </div>
      </div>

      <div className="media-card">
     <div class="card">
        <img src="https://dev-upkl.upkl.in//files/ami.jpg" alt="Card Image"/>
        <div class="overlay">
        <Link to="https://zeenews.india.com/other-sports/uttar-pradesh-kabaddi-league-upkl-2024-auction-arjun-deswal-and-vinay-sold-for-highest-price-2757832.html" target="_blank">

            <h2 class="title">Uttar Pradesh Kabaddi League (UPKL) 2024 Auction</h2>
            <p class="date">June 12, 2024</p>
            </Link>
        </div>
    </div>
      </div>



      <div className="media-card">
     <div class="card">
        <img src="https://dev-upkl.upkl.in//files/Vsp%20News%20(2).jpg" alt="Card Image"/>
        <div class="overlay">
            <Link to="https://www.kabaddiadda.com/articles/uttar-pradesh-kabaddi-league-new-chapter-kabaddi-indias-most-populous-state" target="_blank">
            <h2 class="title">Uttar Pradesh Kabaddi League: 8 teams to participate 
            </h2>
            <p class="date">June 12, 2024</p>
            </Link>
        </div>
    </div>
      </div>


      
    </OwlCarousel>

   




</section>
{/* 
<section className='newsecond'>
<OwlCarousel className="owl-theme1"  {...options3}>
    <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>

      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>


      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>
      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>


      <div className="media-card">
     <div class="card">
        <img src={news} alt="Card Image"/>
        <div class="overlay">
            <h2 class="title">Rahul Chaudhary strikes at the squad Atmosphere..</h2>
            <p class="date">June 12, 2024</p>
        </div>
    </div>
      </div>
    </OwlCarousel>
    
    </section> */}
    </>
  )
}
