import React from 'react'
import p1 from './image/jio.png'
import p2 from './image/somy.png'

import p3 from './image/dd sports (1).png'
import p4 from './image/redfm.png'

export default function Parters() {
  return (
   <>
  </>
  )
}
