import React from 'react'
import Preheader from './Preheader'
import Parters from './Parters'
import Footer from './Footer'
import player1 from './navineet.jpg'
import player2 from './playerimg/14 karan yadav (1).jpg'
import player4 from './playerimg/02 robin chaudhary.jpg'

import player5 from './playerimg/15 pankaj.jpg'
import player6 from './playerimg/03 harsh dhaka.jpg'
import player7 from './playerimg/07 vishal patil.jpg'
import player8 from './playerimg/08 shakti chaudhary.jpg'
import player9 from './playerimg/11 lakshya tomer.jpg'
import player10 from './playerimg/10 lucky kumar.jpg'
import player11 from './playerimg/04 arjun singh.jpg'
import player12 from './playerimg/05 abhishek dangi.jpg'
import { Helmet } from 'react-helmet'
import team1 from './playerimg/harendra kumar.jpg'

export default function Squad() {
  return (
   <>
   <Helmet>
        <title>
        Meet the Brij Stars Players - Names, Allrounders, Raiders, and Defenders


        </title>
        <meta
          name="title"
          content="Meet the Brij Stars Players - Names, Allrounders, Raiders, and Defenders.

"
        />
        <meta
          name="keywords"
          content=" 
 


Brij stars players
Brij stars players name
players of Brij stars
Brij stars Allrounders
Brij stars raiders
Brij stars defenders
"
        />

        <meta
          name="description"
          content="Explore the complete squad of Brij Stars, featuring top players' names, allrounders, raiders, and defenders. Get to know the talented team members who make Brij Stars a formidable force in kabaddi. Discover their skills and contributions to the team.
          

"
        />

        <link rel="canonical" href="https://brijstars/squad" />
      </Helmet>

     <Preheader/>
   <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                <div className="newheading1">
  <h5>Squad</h5>
  <div className="line"></div>
</div>          
                </ol>
            </nav>
        </div>
        </div>
<section className='playersquad'>
    
<div className='container-fluid'>
      <div className='row'>
        <div className='col-md-3 col-12'>
            <img src={player1} className='img-fluid'/>
        </div>

        <div className='col-md-3 col-12'>
            <img src={player2} className='img-fluid'/>
        </div>
       
        <div className='col-md-3 col-12'>
            <img src={player4} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player5} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player6} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player7} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player8} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player9} className='img-fluid'/>
        </div>
        <div className='col-md-3 col-12'>
            <img src={player10} className='img-fluid'/>
        </div>

        <div className='col-md-3 col-12'>
            <img src={player11} className='img-fluid'/>
        </div>


        <div className='col-md-3 col-12'>
            <img src={player12} className='img-fluid'/>
        </div>
      </div>
        </div>
</section>

        <Parters/>
        <Footer/>
   
   </>
  )
}
