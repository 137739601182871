// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Standing from './Standing';
import About from './About';
import Squad from './Squad';
import Newpage from './Newpage';
import Auction from './Auction';
import Video from './Video';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/standing" element={<Standing/>} />
        <Route path="/squad" element={<Squad/>} />
        {/* <Route path="/news" element={<Newpage/>} /> */}
        <Route path="/auction" element={<Auction/>} />
        <Route path="/video" element={<Video/>} />




      </Routes>
    </Router>
  );
};

export default App;
