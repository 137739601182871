import React from "react";
import Preheader from "./Preheader";
import Parters from "./Parters";
import Footer from "./Footer";
import founder from "./image/founderbrij.jpg";
import team1 from "./image/t1.jpg";
import team2 from "./image/t2.jpg";
import team3 from "./image/t31.png";
import team4 from "./image/t41.png";
import aboutus from "./image/about-us.jpg";
import { Helmet } from "react-helmet";
export default function Video() {
  return (
    <>
      <Helmet>
        <title>
          Watch Brij Stars - Founded by Mr. Netrapal Singh Solanki, A Legacy of
          Kabaddi in Mathura.
        </title>
        <meta
          name="title"
          content="About Brij Stars - Founded by Mr. Netrapal Singh Solanki, A Legacy of Kabaddi in Mathura.

"
        />
        <meta
          name="keywords"
          content=" 
Brij stars
Founder of Brij stars
Uttar Pradesh Kabaddi League
kabaddi team 2024
kabaddi stars
Kabaddi team of Mathura
"
        />

        <meta
          name="description"
          content="Brij Stars, the prominent kabaddi team from Mathura, founded by passionate kabaddi stars. Competing in the Uttar Pradesh Kabaddi League 2024, BrijStars showcases exceptional talent and dedication. Learn more about our journey and achievements in the world of kabaddi.

"
        />

        <link rel="canonical" href="https://brijstars./about" />
      </Helmet>

      <Preheader />
      <div class="container-fluid p-0">
        <div class="breadcrumb-bg">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <div className="newheading1">
                <h5>Watch Videos</h5>
                <div className="line"></div>
              </div>{" "}
            </ol>
          </nav>
        </div>
      </div>

      <section className="about--us1">
        <div className="container-fluid">
          <div className="abou-us">
            <div className="row">
              <div className="col-md-6 col-12">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/9grq2vzQTtA?si=QqVESM5S0zm7Kdtr"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-md-6 col-12">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/Sf6eRmtBcCI?si=4iN1CHGXc-gHbwBE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-md-6 col-12">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/ULC_WCqbWCg?si=tuaHX4v3ED0s8hnG"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-md-6 col-12">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/hTm07KmqkpA?si=Nx5-czqSubZc5a0A"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-md-6 col-12">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/wCevui5zevU?si=OzPX0BgbOYKqu41s"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>

              <div className="col-md-6 col-12">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/HOtQBjHYgd4?si=pSp9sw2boWhlHBEX"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>{" "}
              </div>

            <div className="col-md-6 col-12">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/SFriViKvHMA?si=093pqO7-jl8qrT52"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>


            <div className="col-md-6 col-12">
            <iframe width="100%" height="315" src="https://www.youtube.com/embed/L5KGPNvpJww?si=BmP9_s4JPqhMfLJL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        </div>

      </section>

      <Parters />
      <Footer />
    </>
  );
}
